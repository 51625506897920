import React from 'react';
import './styles.scss';

class GDPR extends React.Component {
    componentDidMount() {
        document.title = 'GDPR | Springworks';
    }

    render() {
        return (
            <div className='gdprWrap'>
                <div className='triviaContainer'>
                    <div className='gdprHeader'>
                        <h1>GDPR Privacy Policy of Springworks</h1>
                        <p>Last updated: Apr 01, 2022</p>
                    </div>

                    <div className='gdprSubHeader'>
                        <h4>Definitions</h4>
                    </div>

                    <div className='gdprBullet'>
                        <h6>Springworks</h6>
                        <p>Springworks means SpringRole Inc, 1447 2nd Street, Suite 200, Santa Monica, CA 90401</p>
                    </div>

                    <div className='gdprBullet'>
                        <h6>GDPR</h6>
                        <p>General Data Protection Regulation Act.</p>
                    </div>

                    <div className='gdprBullet'>
                        <h6>Data Controller</h6>
                        <p>
                            Data Controller means the natural or legal person who (either alone or jointly or in common
                            with other persons) determines the purposes for which and the manner in which any personal
                            information are, or are to be, processed.
                        </p>
                    </div>

                    <div className='gdprBullet'>
                        <h6>Data Processor</h6>
                        <p>
                            Data Processor means any natural or legal person who processes the data on behalf of the
                            Data Controller.
                        </p>
                    </div>

                    <div className='gdprBullet'>
                        <h6>Data Subject</h6>
                        <p>
                            Data Subject is any living individual who is using our Service and is the subject of
                            Personal Data.
                        </p>
                    </div>

                    <div className='gdprSubHeader'>
                        <h4>1. Principles for processing personal data</h4>
                        <p>Our principles for processing personal data are:</p>
                    </div>

                    <div className='gdprBullet'>
                        <h6>Fairness and lawfulness.</h6>
                        <p>
                            When we process personal data, the individual rights of the Data Subjects must be protected.
                            All personal data must be collected and processed in a legal and fair manner.
                        </p>
                    </div>

                    <div className='gdprBullet'>
                        <h6>Restricted to a specific purpose.</h6>
                        <p>The personal data of Data Subject must be processed only for specific purposes.</p>
                    </div>

                    <div className='gdprBullet'>
                        <h6>Transparency.</h6>
                        <p>
                            The Data Subject must be informed of how his/her data is being collected, processed and
                            used.
                        </p>
                    </div>

                    <div className='gdprSubHeader'>
                        <h4>2. What personal data we collect and process</h4>
                        <p>
                            <b>Springworks</b> collects several different types of personal data for various purposes.
                            Personal Data may include, but is not limited to:
                        </p>
                    </div>

                    <div className='gdprBullet normalTxt'>
                        <h6>Email address </h6>
                        <h6>First name and last name </h6>
                        <h6>City </h6>
                    </div>

                    <div className='gdprSubHeader'>
                        <h4>3. How we use the personal data</h4>
                        <p>
                            <b>Springworks</b> uses the collected personal data for various purposes:
                        </p>
                    </div>

                    <div className='gdprBullet normalTxt'>
                        <h6>To provide you with services</h6>
                        <h6>To notify you about changes to our services and/or products</h6>
                        <h6>To provide customer support</h6>
                        <h6>To gather analysis or valuable information so that we can improve our services</h6>
                        <h6>To detect, prevent and address technical issues</h6>
                    </div>

                    <div className='gdprSubHeader'>
                        <h4>4. Legal basis for collecting and processing personal data</h4>
                        <p>
                            <b>Springworks</b> legal basis for collecting and using the personal data described in this
                            Data Protection Policy depends on the personal data we collect and the specific context in
                            which we collect the information:
                        </p>
                    </div>

                    <div className='gdprBullet normalTxt'>
                        <h6>
                            <b>Springworks</b> needs to perform a contract with you
                        </h6>
                        <h6>
                            You have given <b>Springworks</b> permission to do so
                        </h6>
                        <h6>
                            Processing your personal data is in <b>Springworks</b> legitimate interests
                        </h6>
                        <h6>
                            <b>Springworks</b> needs to comply with the law
                        </h6>
                    </div>

                    <div className='gdprSubHeader'>
                        <h4>6. Data protection rights</h4>
                        <p>
                            If you are a resident of the European Economic Area (EEA), you have certain data protection
                            rights. If you wish to be informed what personal data we hold about you and if you want it
                            to be removed from our systems, please contact us.
                        </p>
                        <p>In certain circumstances, you have the following data protection rights:</p>
                    </div>

                    <div className='gdprBullet normalTxt'>
                        <h6>The right to access, update or to delete the information we have on you</h6>
                        <h6>The right of rectification</h6>
                        <h6>The right to object</h6>
                        <h6>The right of restriction</h6>
                        <h6>The right to data portability</h6>
                        <h6>The right to withdraw consent</h6>
                    </div>
                </div>
            </div>
        );
    }
}

export default GDPR;
